import React from 'react'

interface ProgressBarProps {
  value: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  const clampedValue = Math.max(0, Math.min(1, value)) // 确保值在0到1之间

  return (
    <div
      className="h-2 w-full overflow-hidden"
      style={{
        background: `linear-gradient(to right, #2A64D2 ${clampedValue * 100}%, #697BE5 ${clampedValue * 100}%)`,
        minWidth: '100%',
      }}
    ></div>
  )
}

export default ProgressBar
