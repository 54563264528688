import sunsetBanner from 'assets/images/sunset-banner.png'
import { LineaXTextIcon } from 'nft/components/icons'
import styled from 'styled-components'

const SunsetBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 40px;
  margin-bottom: 0;

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .sunset-banner-content {
    display: flex;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .sunset-banner-content-left {
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 100px;
      width: 280px;
      height: 180px;

      @media (max-width: 768px) {
        display: none;
        margin-right: 0;
        margin-bottom: 20px;
        width: 200px;
        height: 120px;

        svg {
          transform: scale(1.4) !important;
        }
      }
    }

    .sunset-banner-content-right {
      width: 100%;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .sunset-banner-content-right-title {
        font-size: 48px;
        font-weight: 600;
        color: #d926a9;

        @media (max-width: 768px) {
          font-size: 26px;
          text-align: center;
          width: 100%;
        }
      }

      .sunset-banner-content-right-description {
        font-size: 20px;
        font-weight: 500;
        color: #fff;

        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          width: 100%;
        }

        span {
          color: #d926a9;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 60px;
  }
`

export const SunsetBanner = () => {
  return (
    <SunsetBannerWrapper>
      <img src={sunsetBanner} alt="sunset banner" />
      <div className="sunset-banner-content">
        <div className="sunset-banner-content-left">
          <LineaXTextIcon width={110} height={40} transform="scale(2.0)" />
        </div>
        <div className="sunset-banner-content-right">
          <div className="sunset-banner-content-right-title">L2X Sunset Announcement</div>
          <div className="sunset-banner-content-right-description">
            L2X is closing its chapter as we sunset our services. Thank you for being part of our story and helping us
            grow. Please ensure all your transactions are complete before <span>Jan 28, 2025</span>.
          </div>
        </div>
      </div>
    </SunsetBannerWrapper>
  )
}
