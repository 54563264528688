import { Trans } from '@lingui/macro'
import { createColumnHelper } from '@tanstack/react-table'
import Row from 'components/Row'
import { Table } from 'components/Table'
import { Cell } from 'components/Table/Cell'
import { HeaderArrow, HeaderSortText, StyledExternalLink, TimestampCell } from 'components/Table/styled'
import { Token } from 'graphql/data/__generated__/types-and-hooks'
import { PoolDetailSwap, useSwaps } from 'graphql/data/pools/usePoolData'
import { OrderDirection } from 'graphql/thegraph/__generated__/types-and-hooks'
import { useMemo } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { formatNumber, NumberType } from 'utils/formatNumbers'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const TableWrapper = styled.div`
  min-height: 256px;
`

enum PoolTransactionColumn {
  Timestamp,
  Type,
  MakerAddress,
  FiatValue,
  InputAmount,
  OutputAmount,
}

const PoolTransactionColumnWidth: { [key in PoolTransactionColumn]: number } = {
  [PoolTransactionColumn.Timestamp]: 120,
  [PoolTransactionColumn.Type]: 144,
  [PoolTransactionColumn.MakerAddress]: 100,
  [PoolTransactionColumn.FiatValue]: 125,
  [PoolTransactionColumn.InputAmount]: 125,
  [PoolTransactionColumn.OutputAmount]: 125,
}

export function PoolDetailsTransactionsTable({
  poolAddress,
  token0,
  token1,
  chainId,
}: {
  poolAddress?: string
  token0?: Token
  token1?: Token
  chainId: number
}) {
  const { swaps, loading, error } = useSwaps(poolAddress)
  const showLoadingSkeleton = loading || !!error

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<PoolDetailSwap>()

    return [
      columnHelper.accessor((row) => row, {
        id: 'timestamp',
        header: () => (
          <Cell minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.Timestamp]} justifyContent="flex-start">
            <Row gap="4px">
              <HeaderArrow direction={OrderDirection.Desc} />
              <HeaderSortText>
                <Trans>Time</Trans>
              </HeaderSortText>
            </Row>
          </Cell>
        ),
        cell: (row) => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.Timestamp]}
            justifyContent="flex-start"
          >
            <TimestampCell
              timestamp={Number(row.getValue?.().timestamp)}
              link={getExplorerLink(
                chainId,
                // row.getValue?.().transaction,
                '',
                ExplorerDataType.TRANSACTION
              )}
            />
          </Cell>
        ),
      }),
      columnHelper.accessor(
        (row) => {
          const color = 'success'
          const text = (
            <span>
              <Trans>Buy</Trans>&nbsp;{token0?.symbol}
            </span>
          )

          return <ThemedText.BodyPrimary color={color}>{text}</ThemedText.BodyPrimary>
        },
        {
          id: 'swap-type',
          header: () => (
            <Cell minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.Type]} justifyContent="flex-start">
              <ThemedText.BodySecondary>
                <Trans>Type</Trans>
              </ThemedText.BodySecondary>
            </Cell>
          ),
          cell: (PoolTransactionTableType) => (
            <Cell
              loading={showLoadingSkeleton}
              minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.Type]}
              justifyContent="flex-start"
            >
              {PoolTransactionTableType.getValue?.()}
            </Cell>
          ),
        }
      ),
      columnHelper.accessor((row) => row.amountUSD, {
        id: 'amountUSD',
        header: () => (
          <Cell minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.FiatValue]} justifyContent="flex-end" grow>
            <ThemedText.BodySecondary>USD</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (row) => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.FiatValue]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodyPrimary>0</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.amount0, {
        id: 'input-amount',
        header: () => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.InputAmount]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodySecondary>{token0?.symbol}</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (inputTokenAmount) => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.InputAmount]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodyPrimary>
              {formatNumber(Math.abs(inputTokenAmount.getValue?.() ?? 0), NumberType.TokenTx)}
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.amount1, {
        id: 'output-amount',
        header: () => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.OutputAmount]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodySecondary>{token1?.symbol}</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (outputTokenAmount) => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.OutputAmount]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodyPrimary>
              {' '}
              {formatNumber(Math.abs(outputTokenAmount.getValue?.() ?? 0), NumberType.TokenTx)}
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.origin, {
        id: 'maker-address',
        header: () => (
          <Cell
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.MakerAddress]}
            justifyContent="flex-end"
            grow
          >
            <ThemedText.BodySecondary>
              <Trans>Wallet</Trans>
            </ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (makerAddress) => (
          <Cell
            loading={showLoadingSkeleton}
            minWidth={PoolTransactionColumnWidth[PoolTransactionColumn.MakerAddress]}
            justifyContent="flex-end"
            grow
          >
            <StyledExternalLink href={getExplorerLink(chainId, makerAddress?.getValue?.(), ExplorerDataType.ADDRESS)}>
              <ThemedText.BodyPrimary>{shortenAddress(makerAddress?.getValue?.(), 0)}</ThemedText.BodyPrimary>
            </StyledExternalLink>
          </Cell>
        ),
      }),
    ]
  }, [showLoadingSkeleton, chainId, token0?.symbol, token1?.symbol])

  return (
    <TableWrapper data-testid="pool-details-transactions-table">
      <Table columns={columns} data={swaps} loading={loading} error={error} maxHeight={600} hideBg />
    </TableWrapper>
  )
}
