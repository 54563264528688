import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

interface Token {
  name: string
  symbol: string
  decimals: number
  address: string
}

interface RouteItem {
  type: string
  tokenIn: Token
  tokenOut: Token
  fee: string
  liquidity: string
  sqrtRatioX96: string
  tickCurrent: string
  amountIn: string
  amountOut: string
}

interface QuoteViaAPI {
  blockNumber: string
  quote: string
  quoteDecimals: string
  quoteGasAdjusted: string
  quoteGasAdjustedDecimals: string
  gasUseEstimateQuote: string
  gasUseEstimateQuoteDecimals: string
  gasUseEstimate: string
  gasUseEstimateUSD: string
  gasPriceWei: string
  route: RouteItem[][]
  routeString: string
  isCache: boolean
  queryStatus: boolean
  timestamp: number
}

export async function fetchQuote(fromToken: Token, toToken: Token, amountEther: string): Promise<QuoteViaAPI> {
  const requestData = {
    amountEther,
    fromToken,
    toToken,
    isCache: true,
    isSubgraph: true,
  }

  try {
    const response: AxiosResponse<QuoteViaAPI> = await axios.post(
      'https://homeapp10500.xzsean.eu.org/smartrouter/getQuote',
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching quote:', error)
    throw error
  }
}

const useGetQuote = (fromToken?: Token, toToken?: Token, amountEther?: string) => {
  const [quote, setQuote] = useState<QuoteViaAPI | undefined>(undefined)

  useEffect(() => {
    const getQuote = async () => {
      if (!fromToken || !toToken || !amountEther) {
        setQuote(undefined)
        return
      }

      try {
        const quoteData = await fetchQuote(fromToken, toToken, amountEther)
        setQuote(quoteData)
      } catch (error) {
        setQuote(undefined)
      }
    }

    // Reset
    setQuote(undefined)

    getQuote()

    // Cleanup function to cancel the request if component unmounts or parameters change
    return () => {
      // Implement cleanup logic if necessary
    }
  }, [fromToken, toToken, amountEther])

  return quote
}

export default useGetQuote
