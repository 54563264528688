import { BigNumber } from '@ethersproject/bignumber'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Protocol } from '@uniswap/router-sdk'
import { TradeType } from '@uniswap/sdk-core'
import { isUniswapXSupportedChain } from 'constants/chains'
import { getTokenInfo } from 'constants/list-data'
import { getClientSideQuote } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms'
import { fetchQuote } from 'pages/Swap/useGetQuote'
import { logSwapQuoteRequest } from 'tracing/swapFlowLoggers'
import { trace } from 'tracing/trace'

import {
  ClassicQuoteData,
  GetQuoteArgs,
  INTERNAL_ROUTER_PREFERENCE_PRICE,
  QuoteMethod,
  QuoteState,
  RouterPreference,
  RoutingConfig,
  SwapRouterNativeAssets,
  TradeResult,
  URAQuoteType,
} from './types'
import { getRouter, transformRoutesToTrade } from './utils'

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL
if (UNISWAP_API_URL === undefined) {
  throw new Error(`UNISWAP_API_URL must be a defined environment variable`)
}

const CLIENT_PARAMS = {
  // protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
  protocols: [Protocol.V3],
}

const protocols: Protocol[] = [Protocol.V2, Protocol.V3, Protocol.MIXED]

// routing API quote query params: https://github.com/Uniswap/routing-api/blob/main/lib/handlers/quote/schema/quote-schema.ts
const DEFAULT_QUERY_PARAMS = {
  protocols,
}

function convertUserInputToDecimal(userInput: number | string, decimals: number): string {
  const inputNumber = BigNumber.from(userInput.toString())
  const multiplier = BigNumber.from(10).pow(decimals)
  const result = inputNumber.mul(multiplier)
  return result.toString()
}

function convertDecimalToUserInput(value: string, decimals: number): string {
  const bigValue = BigNumber.from(value)
  const divisor = BigNumber.from(10).pow(decimals)
  const integerPart = bigValue.div(divisor).toString()
  const decimalPart = bigValue.mod(divisor).toString().padStart(decimals, '0')

  // 去除末尾多余的 0
  let result = `${integerPart}.${decimalPart.replace(/0+$/, '')}`

  // 如果小数部分为空,则返回整数部分
  if (result.endsWith('.')) {
    result = integerPart
  }
  return result
}

function getQuoteLatencyMeasure(mark: PerformanceMark): PerformanceMeasure {
  performance.mark('quote-fetch-end')
  return performance.measure('quote-fetch-latency', mark.name, 'quote-fetch-end')
}

function getRoutingAPIConfig(args: GetQuoteArgs): RoutingConfig {
  const {
    account,
    tradeType,
    tokenOutAddress,
    tokenInChainId,
    uniswapXForceSyntheticQuotes,
    uniswapXEthOutputEnabled,
    uniswapXExactOutputEnabled,
    routerPreference,
  } = args

  const uniswapx = {
    useSyntheticQuotes: uniswapXForceSyntheticQuotes,
    // Protocol supports swap+send to different destination address, but
    // for now recipient === swapper
    recipient: account,
    swapper: account,
    routingType: URAQuoteType.DUTCH_LIMIT,
  }

  const classic = {
    ...DEFAULT_QUERY_PARAMS,
    routingType: URAQuoteType.CLASSIC,
  }

  const tokenOutIsNative = Object.values(SwapRouterNativeAssets).includes(tokenOutAddress as SwapRouterNativeAssets)

  // UniswapX doesn't support native out, exact-out, or non-mainnet trades (yet),
  // so even if the user has selected UniswapX as their router preference, force them to receive a Classic quote.
  if (
    !args.uniswapXEnabled ||
    (args.userDisabledUniswapX && routerPreference !== RouterPreference.X) ||
    (tokenOutIsNative && !uniswapXEthOutputEnabled) ||
    (!uniswapXExactOutputEnabled && tradeType === TradeType.EXACT_OUTPUT) ||
    !isUniswapXSupportedChain(tokenInChainId) ||
    routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE
  ) {
    return [classic]
  }

  return [uniswapx, classic]
}

export const routingApi = createApi({
  reducerPath: 'routingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: UNISWAP_API_URL,
  }),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      async onQueryStarted(args: GetQuoteArgs, { queryFulfilled }) {
        trace(
          'quote',
          async ({ setTraceError, setTraceStatus }) => {
            try {
              await queryFulfilled
            } catch (error: unknown) {
              if (error && typeof error === 'object' && 'error' in error) {
                const queryError = (error as Record<'error', FetchBaseQueryError>).error
                if (typeof queryError.status === 'number') {
                  setTraceStatus(queryError.status)
                }
                setTraceError(queryError)
              } else {
                throw error
              }
            }
          },
          {
            data: {
              ...args,
              isPrice: args.routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE,
              isAutoRouter: args.routerPreference === RouterPreference.API,
            },
          }
        )
      },
      // @ts-ignore
      async queryFn(args, _api, _extraOptions, fetch) {
        console.log('queryFn', args)
        const fellBack = false
        logSwapQuoteRequest(args.tokenInChainId, args.routerPreference)
        const quoteStartMark = performance.mark(`quote-fetch-start-${Date.now()}`)
        // if (shouldUseAPIRouter(args)) {
        //   fellBack = true
        //   try {
        //     const {
        //       tokenInAddress,
        //       tokenInChainId,
        //       tokenOutAddress,
        //       tokenOutChainId,
        //       amount,
        //       tradeType,
        //       forceUniswapXOn,
        //     } = args
        //     const type = isExactInput(tradeType) ? 'EXACT_INPUT' : 'EXACT_OUTPUT'

        //     const requestBody = {
        //       tokenInChainId,
        //       tokenIn: tokenInAddress,
        //       tokenOutChainId,
        //       tokenOut: tokenOutAddress,
        //       amount,
        //       type,
        //       // if forceUniswapXOn is not ON, then use the backend's default value
        //       useUniswapX: forceUniswapXOn || undefined,
        //       configs: getRoutingAPIConfig(args),
        //     }

        //     const response = await fetch({
        //       method: 'POST',
        //       url: '/quote',
        //       body: JSON.stringify(requestBody),
        //     })

        //     if (response.error) {
        //       try {
        //         // cast as any here because we do a runtime check on it being an object before indexing into .errorCode
        //         const errorData = response.error.data as any
        //         // NO_ROUTE should be treated as a valid response to prevent retries.
        //         if (
        //           typeof errorData === 'object' &&
        //           (errorData?.errorCode === 'NO_ROUTE' || errorData?.detail === 'No quotes available')
        //         ) {
        //           Sentry.withScope((scope) => {
        //             scope.setExtra('requestBody', requestBody)
        //             scope.setExtra('response', response)
        //             Sentry.captureException(new Error("No routes found for user's quote request, alert Routing Team"))
        //           })
        //           return {
        //             data: { state: QuoteState.NOT_FOUND, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
        //           }
        //         }
        //       } catch {
        //         throw response.error
        //       }
        //     }

        //     const uraQuoteResponse = response.data as URAQuoteResponse
        //     const tradeResult = await transformRoutesToTrade(args, uraQuoteResponse, QuoteMethod.ROUTING_API)
        //     return { data: { ...tradeResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
        //   } catch (error: any) {
        //     console.warn(
        //       `GetQuote failed on Unified Routing API, falling back to client: ${
        //         error?.message ?? error?.detail ?? error
        //       }`
        //     )
        //   }
        // }
        try {
          if (args.tokenInSymbol && args.tokenOutSymbol) {
            const inputToken = {
              name: args.tokenInSymbol,
              symbol: args.tokenInSymbol,
              decimals: args.tokenInDecimals,
              address: getTokenInfo(args.tokenInSymbol, args.tokenInChainId)?.address || args.tokenInAddress,
            }
            const outputToken = {
              name: args.tokenOutSymbol,
              symbol: args.tokenOutSymbol,
              decimals: args.tokenOutDecimals,
              address: getTokenInfo(args.tokenOutSymbol, args.tokenOutChainId)?.address || args.tokenOutAddress,
            }

            const quoteRep = await fetchQuote(
              args.tradeType === 0 ? inputToken : outputToken,
              args.tradeType === 0 ? outputToken : inputToken,
              convertDecimalToUserInput(args.amount, (args.tradeType === 0 ? inputToken : outputToken).decimals)
            )

            if (quoteRep.route.length <= 0) {
              throw new Error('no router from api')
            }

            const result: ClassicQuoteData = {
              methodParameters: undefined,
              blockNumber: quoteRep.blockNumber,
              amount: convertDecimalToUserInput(
                args.amount,
                (args.tradeType === 0 ? inputToken : outputToken).decimals
              ),
              amountDecimals: args.amount,
              quote: quoteRep.quote,
              quoteDecimals: quoteRep.quoteDecimals,
              quoteGasAdjusted: quoteRep.quoteGasAdjusted,
              quoteGasAdjustedDecimals: quoteRep.quoteGasAdjusted,
              gasUseEstimateQuote: quoteRep.gasUseEstimateQuote,
              gasUseEstimateQuoteDecimals: quoteRep.gasUseEstimateQuoteDecimals,
              gasUseEstimate: quoteRep.gasUseEstimate,
              gasUseEstimateUSD: quoteRep.gasUseEstimateUSD,
              gasPriceWei: quoteRep.gasPriceWei,
              // @ts-ignore
              route: quoteRep.route.map((r) => {
                return r.map((p) => {
                  return {
                    type: 'v3-pool',
                    tokenIn: p.tokenIn,
                    tokenOut: p.tokenOut,
                    fee: p.fee,
                    liquidity: p.liquidity,
                    sqrtRatioX96: p.sqrtRatioX96,
                    tickCurrent: p.tickCurrent,
                    amountIn: p.amountIn,
                    amountOut: p.amountOut,
                  }
                })
              }),
              routeString: quoteRep.routeString,
            }

            const method = fellBack ? QuoteMethod.CLIENT_SIDE_FALLBACK : QuoteMethod.CLIENT_SIDE
            const quoteResult = {
              state: QuoteState.SUCCESS,
              data: { routing: URAQuoteType.CLASSIC, quote: result, allQuotes: [] },
            }
            console.log('quoteResult from api', quoteResult)

            if (quoteResult.state === QuoteState.SUCCESS) {
              // @ts-ignore
              const trade = await transformRoutesToTrade(args, quoteResult.data, method)
              console.log('trade from api', trade)

              return {
                data: { ...trade, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
              }
            } else {
              return { data: { ...quoteResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
            }
          }
        } catch (e) {
          console.error(e)
        }

        try {
          // console.log('falling back to client')
          const method = fellBack ? QuoteMethod.CLIENT_SIDE_FALLBACK : QuoteMethod.CLIENT_SIDE
          const router = getRouter(args.tokenInChainId)
          const quoteResult = await getClientSideQuote(args, router as any, CLIENT_PARAMS)
          console.log('quoteResult from chain', quoteResult)
          if (quoteResult.state === QuoteState.SUCCESS) {
            const trade = await transformRoutesToTrade(args, quoteResult.data, method)
            console.log('trade from chain', trade)

            return {
              data: { ...trade, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
            }
          } else {
            return { data: { ...quoteResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
          }
        } catch (error: any) {
          console.warn(`GetQuote failed on client: ${error}`)
          return {
            error: { status: 'CUSTOM_ERROR', error: error?.detail ?? error?.message ?? error },
          }
        }
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = routingApi
export const useGetQuoteQueryState = routingApi.endpoints.getQuote.useQueryState
