type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
}

export const ClosedCircle = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <g clipPath="url(#clip0_2958_135280)">
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#9B9B9B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.46484 2.46509L9.53484 9.53509" stroke="#9B9B9B" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2958_135280">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const DoubleArrow = (props: SVGProps) => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.00284 8.5L0.184659 4.68182L4.00284 0.863636L4.65909 1.51136L1.95739 4.21307H12.1335L9.43182 1.51136L10.0881 0.863636L13.9062 4.68182L10.0881 8.5L9.43182 7.84375L12.1335 5.15057H1.95739L4.65909 7.84375L4.00284 8.5Z"
      fill="#5E5E5E"
    />
  </svg>
)

export const SwapIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.167 2.39844L10.167 14.3984L14.167 10.3984" stroke="white" strokeLinejoin="bevel" />
    <path d="M6.83301 14.6641L6.83301 2.66406L2.83301 6.66407" stroke="white" strokeLinejoin="bevel" />
  </svg>
)

export const CopyIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.83301 4.92251V3.38281C4.83301 2.86505 5.25274 2.44531 5.77051 2.44531H13.8955C14.4133 2.44531 14.833 2.86505 14.833 3.38281V11.5078C14.833 12.0256 14.4133 12.4453 13.8955 12.4453H12.3384"
      stroke="#A6ADBA"
    />
    <path
      d="M11.2295 5.10938H3.10449C2.58673 5.10938 2.16699 5.52911 2.16699 6.04688V14.1719C2.16699 14.6896 2.58673 15.1094 3.10449 15.1094H11.2295C11.7473 15.1094 12.167 14.6896 12.167 14.1719V6.04688C12.167 5.52911 11.7473 5.10938 11.2295 5.10938Z"
      stroke="#A6ADBA"
    />
  </svg>
)

export const AddLiquidity = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11186_6215)">
      <path
        d="M1.33268 8.39714C1.33268 12.079 4.31745 15.0638 7.99935 15.0638C11.6812 15.0638 14.666 12.079 14.666 8.39714C14.666 4.71524 11.6812 1.73047 7.99935 1.73047C4.31745 1.73047 1.33268 4.71523 1.33268 8.39714Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path d="M10.666 8.39844L5.33268 8.39844" stroke="white" strokeWidth="1.5" />
      <path d="M8 5.73047L8 11.0638" stroke="white" strokeWidth="1.5" />
    </g>
    <defs>
      <clipPath id="clip0_11186_6215">
        <rect width="16" height="16" fill="white" transform="translate(16 0.398438) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
)

export const CloseIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.834 3.06641L3.16732 13.7331" stroke="white" />
    <path d="M3.16732 3.06641L13.834 13.7331" stroke="white" />
  </svg>
)

export const BackIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.39941 12.3984H21.3994" stroke="#A6ADBA" strokeWidth="1.5" />
    <path d="M9.3994 18.3984L3.39941 12.3984L9.3994 6.39844" stroke="#A6ADBA" strokeWidth="1.5" />
  </svg>
)

export const EthersIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" transform="translate(0.5 0.777344)" fill="#2A303C" />
    <path
      d="M10.1235 14.3995C10.1235 14.3162 10.14 14.2337 10.1719 14.1568C10.2039 14.0799 10.2508 14.0101 10.3098 13.9514C10.3688 13.8926 10.4389 13.8461 10.5159 13.8145C10.593 13.7829 10.6755 13.7668 10.7588 13.7672L11.8121 13.7707C11.98 13.7707 12.1411 13.8374 12.2598 13.9561C12.3786 14.0749 12.4453 14.2359 12.4453 14.4039V18.3866C12.5639 18.3514 12.7162 18.3138 12.8828 18.2746C12.9986 18.2475 13.1017 18.1819 13.1756 18.0887C13.2494 17.9955 13.2896 17.8801 13.2895 17.7611V12.8209C13.2895 12.6529 13.3562 12.4918 13.475 12.3731C13.5937 12.2543 13.7548 12.1876 13.9227 12.1875H14.9781C15.1461 12.1876 15.3071 12.2543 15.4259 12.3731C15.5446 12.4918 15.6113 12.6529 15.6113 12.8209V17.406C15.6113 17.406 15.8756 17.2991 16.133 17.1904C16.2285 17.1499 16.3102 17.0823 16.3675 16.9958C16.4249 16.9093 16.4555 16.8078 16.4556 16.7039V11.2378C16.4556 11.0699 16.5223 10.9089 16.6411 10.7901C16.7599 10.6714 16.9208 10.6047 17.0888 10.6046H18.1442C18.3121 10.6046 18.4732 10.6713 18.5918 10.7901C18.7106 10.9089 18.7774 11.0699 18.7774 11.2378V15.7391C19.6924 15.076 20.6196 14.2784 21.3555 13.3194C21.4623 13.1802 21.5329 13.0168 21.561 12.8437C21.5893 12.6705 21.5742 12.4931 21.5171 12.3272C21.1765 11.3472 20.6352 10.4491 19.9276 9.69035C19.2199 8.93161 18.3617 8.32894 17.4077 7.92093C16.4539 7.51291 15.4253 7.30852 14.3878 7.32084C13.3504 7.33316 12.327 7.56192 11.383 7.99248C10.4391 8.42303 9.59543 9.0459 8.90603 9.82124C8.21663 10.5966 7.69667 11.5073 7.37944 12.4951C7.06221 13.4829 6.95469 14.5261 7.06377 15.5578C7.17284 16.5895 7.49609 17.5872 8.01285 18.4869C8.10287 18.6421 8.23532 18.7683 8.39461 18.8509C8.55391 18.9334 8.73344 18.9687 8.91215 18.9529C9.11177 18.9353 9.36032 18.9104 9.65583 18.8757C9.78447 18.861 9.90324 18.7997 9.98951 18.7032C10.0758 18.6066 10.1235 18.4817 10.1236 18.3523L10.1235 14.3995Z"
      fill="#A6ADBA"
      fillOpacity="0.6"
    />
    <path
      d="M10.1006 20.7881C11.213 21.5974 12.5277 22.0832 13.8991 22.1918C15.2705 22.3002 16.6452 22.0272 17.8711 21.4028C19.097 20.7784 20.1261 19.8271 20.8449 18.6542C21.5636 17.4811 21.9439 16.1321 21.9435 14.7564C21.9435 14.5848 21.9355 14.4149 21.9242 14.2461C19.1996 18.3094 14.169 20.2098 10.1006 20.7881Z"
      fill="#A6ADBA"
      fillOpacity="0.6"
    />
  </svg>
)

export const CopyURLIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66504 8.06635C6.95149 8.44931 7.31696 8.76618 7.73664 8.99547C8.15633 9.22477 8.62042 9.36112 9.09743 9.39528C9.57445 9.42944 10.0532 9.36062 10.5013 9.19347C10.9494 9.02633 11.3563 8.76477 11.6944 8.42654L13.6954 6.42548C14.303 5.79647 14.6391 4.95402 14.6315 4.07956C14.6239 3.20511 14.2732 2.36862 13.6548 1.75027C13.0365 1.13191 12.2 0.781162 11.3255 0.773564C10.4511 0.765965 9.60861 1.10212 8.9796 1.70964L7.83233 2.85025"
      stroke="#A6ADBA"
      strokeOpacity="0.6"
      strokeWidth="1.33404"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33379 6.73198C9.04734 6.34902 8.68188 6.03215 8.26219 5.80286C7.84251 5.57357 7.37842 5.43721 6.9014 5.40305C6.42439 5.36889 5.9456 5.43771 5.49752 5.60486C5.04945 5.77201 4.64256 6.03356 4.30445 6.37179L2.30339 8.37285C1.69587 9.00186 1.35971 9.84432 1.36731 10.7188C1.37491 11.5932 1.72566 12.4297 2.34402 13.0481C2.96237 13.6664 3.79886 14.0172 4.67331 14.0248C5.54777 14.0324 6.39022 13.6962 7.01923 13.0887L8.15984 11.9481"
      stroke="#A6ADBA"
      strokeOpacity="0.6"
      strokeWidth="1.33404"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
