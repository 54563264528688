import { gql, useQuery } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'
import {
  Chain,
  ProtocolVersion,
  Token,
  useV2PairQuery,
  useV3PoolQuery,
} from 'graphql/data/__generated__/types-and-hooks'
import { V2_BIPS } from 'graphql/data/pools/useTopPools'
import { chainIdToBackendName } from 'graphql/data/util'
import ms from 'ms'
import { useMemo } from 'react'

export interface PoolData {
  // basic pool info
  address: string
  feeTier?: number
  txCount?: number
  protocolVersion?: ProtocolVersion

  // token info
  token0: Token
  tvlToken0?: number
  token0Price?: number

  token1: Token
  tvlToken1?: number
  token1Price?: number

  // volume
  volumeUSD24H?: number
  volumeUSD24HChange?: number

  // liquidity
  tvlUSD?: number
  tvlUSDChange?: number
}

/**
 * Given an array of historical volume, calculate the 24h % change in volume
 */
function calc24HVolChange(historicalVolume?: { value: number; timestamp: number }[]) {
  if (!historicalVolume) {
    return undefined
  }
  const currentTime = new Date().getTime()
  const dayAgo = (currentTime - ms('1d')) / 1000
  const twoDaysAgo = (currentTime - ms('2d')) / 1000

  const volume24h = historicalVolume
    .filter((entry) => entry.timestamp >= dayAgo)
    .reduce((acc, cur) => acc + cur.value, 0)
  const volume48h = historicalVolume
    .filter((entry) => entry.timestamp >= twoDaysAgo && entry.timestamp < dayAgo)
    .reduce((acc, cur) => acc + cur.value, 0)
  return ((volume24h - volume48h) / volume48h) * 100
}

/**
 * Queries both v3 and v2 for pool data
 * @param poolAddress
 * @param chainId
 * @returns
 */
export function usePoolData(
  poolAddress: string,
  chainId?: ChainId
): {
  loading: boolean
  error: boolean
  data?: PoolData
} {
  const {
    loading: loadingV3,
    error: errorV3,
    data: dataV3,
  } = useV3PoolQuery({
    variables: { chain: chainIdToBackendName(chainId), address: poolAddress },
  })

  console.log('poolAddress', poolAddress)
  const {
    loading: loadingV2,
    error: errorV2,
    data: dataV2,
  } = useV2PairQuery({
    variables: { address: poolAddress },
    skip: chainId !== ChainId.MAINNET,
  })

  return useMemo(() => {
    const anyError = Boolean(errorV3 || (errorV2 && chainId === ChainId.MAINNET))
    const anyLoading = Boolean(loadingV3 || (loadingV2 && chainId === ChainId.MAINNET))

    // return early if not all data yet
    if (anyError || anyLoading) {
      return {
        loading: anyLoading,
        error: anyError,
        data: undefined,
      }
    }

    const pool = dataV3?.v3Pool ?? dataV2?.v2Pair ?? undefined
    const feeTier = dataV3?.v3Pool?.feeTier ?? V2_BIPS

    return {
      data: pool
        ? {
            address: pool.address,
            txCount: pool.txCount,
            protocolVersion: pool.protocolVersion,
            token0: pool.token0 as Token,
            tvlToken0: pool.token0Supply,
            token0Price: pool.token0?.project?.markets?.[0]?.price?.value,
            token1: pool.token1 as Token,
            tvlToken1: pool.token1Supply,
            token1Price: pool.token1?.project?.markets?.[0]?.price?.value,
            feeTier,
            volumeUSD24H: pool.volume24h?.value,
            volumeUSD24HChange: calc24HVolChange(pool.historicalVolume?.concat()),
            tvlUSD: pool.totalLiquidity?.value,
            tvlUSDChange: pool.totalLiquidityPercentChange24h?.value,
          }
        : undefined,
      error: anyError,
      loading: anyLoading,
    }
  }, [chainId, dataV2?.v2Pair, dataV3?.v3Pool, errorV2, errorV3, loadingV2, loadingV3])
}

const POOL_DETAIL_QUERY = gql`
  query PoolDetail($id: String!) {
    pool(id: $id) {
      feeTier
      token0 {
        id
        name
        symbol
        decimals
        totalSupply
      }
      token1Price
      token0Price
      totalValueLockedUSD
      volumeUSD
      txCount
      token1 {
        decimals
        id
        name
        symbol
        totalSupply
      }
      liquidity
    }
  }
`

export const usePoolDetail = (id: string) => {
  const { loading, error, data } = useQuery<{ pool: PoolDetail }>(POOL_DETAIL_QUERY, {
    variables: { id },
  })

  return {
    loading,
    error,
    data: data?.pool,
  }
}

export const wrapPoolDetailToken = (chain: Chain, token?: PoolDetailToken): Token | undefined => {
  if (!token) return undefined
  return {
    id: token.id,
    chain,
    address: token.id,
    symbol: token.symbol,
    name: token.name,
    decimals: token.decimals,
  } as Token
}

interface PoolDetailDayData {
  volumeToken0: number
  volumeToken1: number
  volumeUSD: number
  liquidity: string
  token0Price: string
  token1Price: string
  date?: string
  periodStartUnix?: string
  sqrtPrice: string
  open: string
  close: string
  high: string
  low: string
  id: string
  tvlUSD: string
  txCount: number
}

export interface PoolDetailToken {
  id: string
  name: string
  symbol: string
  decimals: number
  totalSupply: number
}

export interface PoolDetail {
  feeTier: number
  token0: PoolDetailToken
  token1Price: number
  token0Price: number
  txCount: number
  token1: PoolDetailToken
  liquidity: number
  tick: string
  poolDayData: PoolDetailDayData[]
  totalValueLockedUSD: string
  volumeUSD: string
}

const POOL_DAY_DATAS_QUERY = gql`
  query PoolDayDatas($poolId: String!) {
    poolDayDatas(where: { pool: $poolId }) {
      close
      date
      feesUSD
      high
      id
      liquidity
      low
      open
      sqrtPrice
      tick
      token0Price
      token1Price
      tvlUSD
      txCount
      volumeToken0
      volumeToken1
      volumeUSD
    }
  }
`

interface PoolDayData {
  close: string
  date: number
  feesUSD: string
  high: string
  id: string
  liquidity: string
  low: string
  open: string
  sqrtPrice: string
  tick: string
  token0Price: string
  token1Price: string
  tvlUSD: string
  txCount: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
}

export const usePoolDayDatas = (poolId?: string) => {
  const { loading, error, data } = useQuery<{ poolDayDatas: PoolDayData[] }>(POOL_DAY_DATAS_QUERY, {
    variables: { poolId },
    skip: !poolId,
  })

  return {
    loading,
    error,
    poolDayDatas: data?.poolDayDatas || [],
  }
}

const POOL_HOUR_DATAS_QUERY = gql`
  query PoolHourDatas($poolId: String!) {
    poolHourDatas(where: { pool: $poolId }) {
      close
      feesUSD
      high
      id
      liquidity
      low
      open
      periodStartUnix
      sqrtPrice
      tick
      token0Price
      token1Price
      tvlUSD
      txCount
      volumeToken0
      volumeToken1
      volumeUSD
    }
  }
`

interface PoolHourData {
  close: string
  feesUSD: string
  high: string
  id: string
  liquidity: string
  low: string
  open: string
  periodStartUnix: number
  sqrtPrice: string
  tick: string
  token0Price: string
  token1Price: string
  tvlUSD: string
  txCount: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
}

export const usePoolHourDatas = (poolId?: string) => {
  const { loading, error, data } = useQuery<{ poolHourDatas: PoolHourData[] }>(POOL_HOUR_DATAS_QUERY, {
    variables: { poolId },
    skip: !poolId,
  })

  return {
    loading,
    error,
    poolHourDatas: data?.poolHourDatas || [],
  }
}

const SWAPS_QUERY = gql`
  query Swaps($poolId: ID!) {
    swaps(where: { pool: $poolId }) {
      timestamp
      amount0
      amount1
      amountUSD
      origin
      tick
      sqrtPriceX96
      token0 {
        symbol
      }
      token1 {
        symbol
      }
    }
  }
`

export interface PoolDetailSwap {
  timestamp: string
  amount0: number
  amount1: number
  amountUSD: number
  origin: string
  tick: number
  sqrtPriceX96: string
  token0: {
    symbol: string
    volume: string
    volumeUSD: string
  }
  token1: {
    symbol: string
    volume: string
    volumeUSD: string
  }
}

export const useSwaps = (poolId?: string) => {
  const { loading, error, data } = useQuery<{ swaps: PoolDetailSwap[] }>(SWAPS_QUERY, {
    variables: { poolId },
    skip: !poolId,
  })

  return {
    loading,
    error,
    swaps: data?.swaps || [],
  }
}

const TOKEN_DETAIL_QUERY = gql`
  query TokenDetail($tokenId: String!) {
    token(id: $tokenId) {
      decimals
      derivedETH
      feesUSD
      id
      name
      poolCount
      symbol
      totalSupply
      totalValueLocked
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      txCount
      untrackedVolumeUSD
      volume
      volumeUSD
    }
  }
`

interface TokenDetail {
  decimals: number
  derivedETH: number
  feesUSD: number
  id: string
  name: string
  poolCount: number
  symbol: string
  totalSupply: number
  totalValueLocked: number
  totalValueLockedUSD: number
  totalValueLockedUSDUntracked: number
  txCount: number
  untrackedVolumeUSD: number
  volume: number
  volumeUSD: number
}

export const useTokenDetail = (tokenId?: string) => {
  const { loading, error, data } = useQuery<{ token: TokenDetail }>(TOKEN_DETAIL_QUERY, {
    variables: { tokenId },
    skip: !tokenId,
  })

  return {
    loading,
    error,
    tokenDetail: data?.token || null,
  }
}

const SWAPS_FOR_TOKEN_QUERY = gql`
  query Swaps($tokenId: ID!) {
    swaps(where: { token1_: { id: $tokenId } }) {
      token0 {
        symbol
        volume
        volumeUSD
      }
      token1 {
        symbol
        volume
        volumeUSD
      }
      timestamp
      tick
      sqrtPriceX96
      sender
      recipient
      amount0
      amount1
      amountUSD
      origin
    }
  }
`

export const useSwapsForToken = (tokenId?: string) => {
  const { loading, error, data } = useQuery<{ swaps: PoolDetailSwap[] }>(SWAPS_FOR_TOKEN_QUERY, {
    variables: { tokenId },
    skip: !tokenId,
  })

  return {
    loading,
    error,
    swaps: data?.swaps || [],
  }
}

const TOKEN_DATAS_QUERY = gql`
  query TokenDatas($tokenId: String!) {
    tokenDayDatas(where: { token: $tokenId }) {
      close
      date
      feesUSD
      high
      id
      low
      open
      priceUSD
      totalValueLocked
      totalValueLockedUSD
      untrackedVolumeUSD
      volume
      volumeUSD
    }
    tokenHourDatas(where: { token: $tokenId }) {
      close
      feesUSD
      high
      id
      low
      open
      periodStartUnix
      priceUSD
      totalValueLocked
      totalValueLockedUSD
      untrackedVolumeUSD
      volume
      volumeUSD
    }
  }
`

interface TokenDayData {
  close: number
  date: number
  feesUSD: number
  high: number
  id: string
  low: number
  open: number
  priceUSD: number
  totalValueLocked: number
  totalValueLockedUSD: number
  untrackedVolumeUSD: number
  volume: number
  volumeUSD: number
}

interface TokenHourData {
  close: number
  date: number
  periodStartUnix: number
  feesUSD: number
  high: number
  id: string
  low: number
  open: number
  priceUSD: number
  totalValueLocked: number
  totalValueLockedUSD: number
  untrackedVolumeUSD: number
  volume: number
  volumeUSD: number
}

interface TokenDatasResponse {
  tokenDayDatas: TokenDayData[]
  tokenHourDatas: TokenHourData[]
}

export const useTokenDatas = (tokenId?: string) => {
  const { loading, error, data } = useQuery<TokenDatasResponse>(TOKEN_DATAS_QUERY, {
    variables: { tokenId },
    skip: !tokenId,
  })

  return {
    loading,
    error,
    tokenDayDatas: data?.tokenDayDatas || [],
    tokenHourDatas: data?.tokenHourDatas || [],
  }
}
