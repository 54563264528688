import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { Menu } from 'components/Menu'
import { RowBetween, RowFixed } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { getChainInfo } from 'constants/chainInfo'
import { DEFAULT_CHAINID } from 'constants/chains'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { AlertTriangle, Inbox } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import styled, { css, useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 870px;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    max-width: 800px;
    padding-top: 48px;
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    max-width: 500px;
    padding-top: 20px;
  }
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.neutral2};
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`
const PoolMenu = styled(Menu)`
  margin-left: 0;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex: 1 1 auto;
    width: 50%;
  }

  a {
    width: 100%;
  }
`
const PoolMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 535;
`
const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  margin-right: 8px;
`

const MoreOptionsText = styled(ThemedText.BodyPrimary)`
  align-items: center;
  display: flex;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 300px;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  /* border-radius: 12px; */
  border: none;
  font-size: 16px;
  padding: 10px 16px;
  width: fit-content;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex: 1 1 auto;
    width: 50%;
  }
`

const MainContentWrapper = styled.main`
  /* background-color: ${({ theme }) => theme.surface1}; */
  background: linear-gradient(90deg, #22b3ec 0%, #8e53e6 100%);
  /* border: 1px solid ${({ theme }) => theme.surface3}; */
  padding: 0 8px 8px 0;
  /* border-radius: 16px; */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ::after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.surface1};
    right: 8px;
    top: -8px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

export function WrongNetworkCard() {
  const theme = useTheme()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const switchChain = useSwitchChain()
  return (
    <>
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <ErrorContainer>
            <ThemedText.BodyPrimary color={theme.neutral3} textAlign="center">
              <NetworkIcon strokeWidth={1.2} />
              <div data-testid="pools-unsupported-err">
                {!connectedChainId ? (
                  <Trans>Please connect your wallet</Trans>
                ) : (
                  <Trans>Your connected network is unsupported.</Trans>
                )}
              </div>
              {switchingChain ? (
                <ButtonPrimary marginTop="40px" $borderRadius="16px" disabled={true}>
                  <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
                </ButtonPrimary>
              ) : !connectedChainId ? (
                <ButtonPrimary marginTop="40px" $borderRadius="16px" onClick={toggleWalletDrawer}>
                  <Trans>Connect Wallet</Trans>
                </ButtonPrimary>
              ) : (
                connectedChainId &&
                ChainId.ZKATANA !== connectedChainId && (
                  <ButtonPrimary
                    marginTop="40px"
                    $borderRadius="16px"
                    onClick={async () => {
                      try {
                        await switchChain(connector, DEFAULT_CHAINID)
                      } catch (error) {
                        if (didUserReject(error)) {
                          // Ignore error, which keeps the user on the previous chain.
                        } else {
                          // TODO(WEB-3306): This UX could be improved to show an error state.
                          throw error
                        }
                      }
                    }}
                  >
                    Connect to {getChainInfo(DEFAULT_CHAINID)?.label}
                  </ButtonPrimary>
                )
              )}

              {/* {connectedChainId && ChainId.ZKATANA !== connectedChainId && (
                    <ButtonPrimary
                      marginTop="40px"
                      $borderRadius="16px"
                      onClick={async () => {
                        try {
                          await switchChain(connector, ChainId.ZKATANA)
                        } catch (error) {
                          if (didUserReject(error)) {
                            // Ignore error, which keeps the user on the previous chain.
                          } else {
                            // TODO(WEB-3306): This UX could be improved to show an error state.
                            throw error
                          }
                        }
                      }}
                    >
                      Connect to {getChainInfo(ChainId.ZKATANA)?.label}
                    </ButtonPrimary>
                  )} */}
            </ThemedText.BodyPrimary>
          </ErrorContainer>
        </AutoColumn>
      </AutoColumn>
      <SwitchLocaleLink />
    </>
  )
}
