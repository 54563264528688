import { t, Trans } from '@lingui/macro'
import DefaultToken from 'assets/svg/icons/default-token.svg'
import { PriceChart } from 'components/Charts2/PriceChart'
import { ChartType, PriceChartType } from 'components/Charts2/utils'
import { VolumeChart } from 'components/Charts2/VolumeChart'
import Column from 'components/Column'
import Row from 'components/Row'
import { ChartTypeDropdown } from 'components/Tokens/TokenDetails/ChartSection2/ChartTypeSelector'
import Tooltip, { TooltipSize } from 'components/Tooltip'
import { getTokenInfo } from 'constants/list-data'
import {
  PoolDetail,
  PoolDetailToken,
  usePoolDayDatas,
  usePoolDetail,
  usePoolHourDatas,
  wrapPoolDetailToken,
} from 'graphql/data/pools/usePoolData'
import { getValidUrlChainName, supportedChainIdFromGQLChain, TimePeriod, unwrapToken } from 'graphql/data/util'
import { useToken } from 'hooks/Tokens'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { UTCTimestamp } from 'lightweight-charts'
import NotFound from 'pages/NotFound'
import { Swap } from 'pages/Swap'
import { TimePeriodGroup, TimePeriodType } from 'pages/TokenDetails/index-v2'
import { useReducer, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS, ClickableStyle, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { currencyId } from 'utils/currencyId'
import { unwrappedToken } from 'utils/unwrappedToken'

import { AddLiquidity, CloseIcon, CopyIcon, EthersIcon, SwapIcon } from './icons'
import { DoubleTokenAndChainLogo, PoolDetailsBreadcrumb, PoolDetailsHeader } from './PoolDetailsHeader'
import { PoolDetailsStats } from './PoolDetailStats'
import { PoolDetailsTransactionsTable } from './PoolDetailsTransactionsTable'

const TDP_CHART_SELECTOR_OPTIONS = [ChartType.PRICE, ChartType.VOLUME, ChartType.TVL] as const

const PageWrapper = styled(Row)`
  padding: 0 16px 52px;
  justify-content: center;
  width: 100%;
  gap: 16px;
  align-items: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 48px 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
`

const LeftColumn = styled(Column)`
  gap: 40px;
  max-width: 780px;
  overflow: hidden;
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
    max-width: unset;
  }
`

const RightColumn = styled(Column)`
  gap: 24px;
  width: 412px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 44px 0px;
    width: 100%;
    min-width: unset;
    & > *:first-child {
      margin-top: -24px;
    }
  }
`

const HR = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.surface3};
  width: 100%;
`

const TableHeader = styled(ThemedText.HeadlineMedium)<{ active: boolean }>`
  color: ${({ theme, active }) => !active && theme.neutral2};
  ${({ disabled }) => !disabled && ClickableStyle}
  user-select: none;
`

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a6adba66;
  padding: 10px 16px;
  background: ${({ theme }) => theme.base200};
  text-align: center;
  color: ${({ theme }) => theme.baseContent1};
  gap: 8px;
  ${ClickableStyle};
`

const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* 添加边框样式 */
  border-style: solid;
  /* 添加边框宽度 */
  border-width: 1px;
  /* 添加边框颜色 */
  border-color: transparent;
  /* 添加边框图片 */
  border-image-source: linear-gradient(90deg, #22b3ec 0%, #8e53e6 100%);
  /* 指定边框图片填充方式 */
  border-image-slice: 1;
  padding: 10px 16px;
  background: ${({ theme }) => theme.base200};
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.baseContent1};
  gap: 8px;
`

function getUnwrappedPoolToken(poolData?: PoolDetail, chainId?: number) {
  return poolData?.token0 && poolData?.token1 && chainId
    ? [unwrapToken(chainId, { address: poolData.token0.id }), unwrapToken(chainId, { address: poolData.token1.id })]
    : [undefined, undefined]
}

const PoolDetails = () => {
  const { poolAddress, chainName } = useParams<{
    poolAddress: string
    chainName: string
  }>()
  const chain = getValidUrlChainName(chainName)
  const chainId = chain && supportedChainIdFromGQLChain(chain)

  const [chartType, setChartType] = useState<ChartType>(ChartType.PRICE)
  const [currentTimePeriod, setCurrentTimePeriod] = useState<TimePeriodType>('1D')
  const [showSwap, setShowSwap] = useState(false)

  const [isCopied, setCopied] = useCopyClipboard()
  const { data: poolData, loading } = usePoolDetail(poolAddress?.toLowerCase() ?? '')
  const [isReversed, toggleReversed] = useReducer((x) => !x, false)
  const unwrappedTokens = getUnwrappedPoolToken(poolData, chainId)
  const [token0, token1] = isReversed ? [poolData?.token0, poolData?.token1] : [poolData?.token1, poolData?.token0]
  const { poolDayDatas } = usePoolDayDatas(poolAddress)
  const { poolHourDatas } = usePoolHourDatas(poolAddress)

  // I dont know why so many token type in this project
  const useToken0 = useToken(token0?.id)
  const useToken1 = useToken(token1?.id)

  const currency0 = useToken0 ? unwrappedToken(useToken0) : undefined
  const currency1 = useToken1 ? unwrappedToken(useToken1) : undefined

  if (!chain || !chainId) return <NotFound />
  if (!loading && !poolData) return <NotFound />

  const wrappedToken0 = wrapPoolDetailToken(chain, token0)
  const wrappedToken1 = wrapPoolDetailToken(chain, token1)

  const token0Info = getTokenInfo(token0?.symbol || '', chainId)
  const token1Info = getTokenInfo(token1?.symbol || '', chainId)

  const currentToken0Price = isReversed ? Number(poolData?.token1Price || 0) : Number(poolData?.token0Price || 0)

  return (
    <PageWrapper>
      <LeftColumn>
        <Column>
          <Column>
            <PoolDetailsBreadcrumb />
          </Column>

          <div className="bg-dark-base-200 p-3">
            <Row>
              <PoolDetailsHeader
                chainId={chainId}
                poolAddress={poolAddress}
                token0={wrappedToken0}
                token1={wrappedToken1}
                feeTier={poolData?.feeTier}
                toggleReversed={toggleReversed}
                loading={loading}
              />
            </Row>
            <Row height={8} />
            {chartType === ChartType.PRICE ? (
              (currentTimePeriod === '1D' ? poolDayDatas : poolHourDatas).length > 0 && (
                <PriceChart
                  type={PriceChartType.LINE}
                  height={356}
                  token0={{
                    address: token0?.id,
                    symbol: token0?.symbol,
                    price: currentToken0Price,
                  }}
                  token1={{
                    symbol: token1?.symbol,
                  }}
                  data={
                    currentTimePeriod === '1D'
                      ? poolDayDatas.map((item) => {
                          return {
                            value: isReversed ? Number(item.token1Price) : Number(item.token0Price),
                            time: item.date as UTCTimestamp,
                            open: Number(item.open),
                            high: Number(item.high),
                            low: Number(item.low),
                            close: Number(item.close),
                          }
                        })
                      : poolHourDatas.map((item) => {
                          return {
                            value: isReversed ? Number(item.token1Price) : Number(item.token0Price),
                            time: item.periodStartUnix as UTCTimestamp,
                            open: Number(item.open),
                            high: Number(item.high),
                            low: Number(item.low),
                            close: Number(item.close),
                          }
                        })
                  }
                  stale={false}
                />
              )
            ) : chartType === ChartType.TVL ? (
              <VolumeChart
                timePeriod={currentTimePeriod === '1D' ? TimePeriod.DAY : TimePeriod.HOUR}
                height={356}
                feeTier={poolData?.feeTier}
                data={
                  currentTimePeriod === '1D'
                    ? poolDayDatas.map((item) => {
                        return {
                          value: Number(item.tvlUSD),
                          time: item.date as UTCTimestamp,
                        }
                      })
                    : poolHourDatas.map((item) => {
                        return {
                          value: Number(item.tvlUSD),
                          time: item.periodStartUnix as UTCTimestamp,
                        }
                      })
                }
                stale={false}
              />
            ) : (
              <VolumeChart
                timePeriod={currentTimePeriod === '1D' ? TimePeriod.DAY : TimePeriod.HOUR}
                height={356}
                feeTier={poolData?.feeTier}
                data={
                  currentTimePeriod === '1D'
                    ? poolDayDatas.map((item) => {
                        return {
                          value: isReversed ? Number(item.volumeToken1) : Number(item.volumeToken0),
                          time: item.date as UTCTimestamp,
                        }
                      })
                    : poolHourDatas.map((item) => {
                        return {
                          value: isReversed ? Number(item.volumeToken1) : Number(item.volumeToken1),
                          time: item.periodStartUnix as UTCTimestamp,
                        }
                      })
                }
                stale={false}
              />
            )}

            <div className="flex flex-row w-full justify-between">
              <TimePeriodGroup value={currentTimePeriod} onChange={setCurrentTimePeriod} />

              <div className="flex flex-row gap-4">
                <ChartTypeDropdown
                  options={TDP_CHART_SELECTOR_OPTIONS}
                  currentChartType={chartType}
                  onSelectOption={(c) => {
                    setChartType(c)
                  }}
                />
              </div>
            </div>
          </div>
        </Column>
        <Column gap="lg">
          <Row gap="16px">
            <TableHeader active>
              <Trans>Transactions</Trans>
            </TableHeader>
          </Row>
          <PoolDetailsTransactionsTable
            chainId={chainId}
            poolAddress={poolAddress}
            token0={wrapPoolDetailToken(chain, poolData?.token0)}
            token1={wrapPoolDetailToken(chain, poolData?.token1)}
          />
        </Column>
      </LeftColumn>
      <RightColumn>
        <div className="flex flex-row w-full gap-3 pt-11">
          {showSwap ? (
            <CloseButton onClick={() => setShowSwap(!showSwap)}>
              <CloseIcon />
              Close
            </CloseButton>
          ) : (
            <PrimaryButton onClick={() => setShowSwap(!showSwap)}>
              <SwapIcon />
              Swap
            </PrimaryButton>
          )}

          <PrimaryButton>
            <AddLiquidity />
            {currency0 && currency1 && (
              <Link
                className="text-white"
                to={`/increase/${currencyId(currency0)}/${currencyId(currency1)}/${poolData?.feeTier || 0 * 1000}`}
              >
                Add Liquidity
              </Link>
            )}
          </PrimaryButton>
        </div>
        {showSwap && <Swap hideBg />}
        {poolData && <PoolDetailsStats poolData={poolData} chainId={chainId} />}
        <div className="flex flex-col bg-dark-base-200 p-3 gap-6">
          <span>Links</span>
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row items-center gap-2">
              {chainId && (
                <DoubleTokenAndChainLogo
                  data-testid="double-token-logo"
                  chainId={chainId}
                  tokens={[wrappedToken0, wrappedToken1]}
                  size={24}
                />
              )}
              <span className="text-lg font-medium text-white">
                {token0?.symbol} / {token1?.symbol}
              </span>
            </div>
            <div className="flex flex-row gap-[10px]">
              <div className="flex flex-row items-center bg-dark-base-100 gap-2 px-2">
                <span>{shortenAddress(poolAddress)}</span>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (poolAddress) {
                      setCopied(poolAddress)
                    }
                  }}
                >
                  <Tooltip
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    placement="bottom"
                    size={TooltipSize.Max}
                    show={isCopied}
                    text={t`Copied`}
                  >
                    <CopyIcon />
                  </Tooltip>
                </div>
              </div>

              <a href={`https://etherscan.io/address/${poolAddress}`}>
                <EthersIcon />
              </a>
            </div>
          </div>

          <TokenLink token={token0} logo={token0Info?.logoURI} />
          <TokenLink token={token1} logo={token1Info?.logoURI} />
        </div>
      </RightColumn>
    </PageWrapper>
  )
}

export const CopyLink = ({ address }: { address?: string }) => {
  const [isCopied, setCopied] = useCopyClipboard()
  return (
    <div className="flex flex-row items-center bg-dark-base-100 gap-2 px-2">
      <span>{shortenAddress(address)}</span>
      <div
        className="cursor-pointer"
        onClick={() => {
          if (address) {
            setCopied(address)
          }
        }}
      >
        <Tooltip
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          placement="bottom"
          size={TooltipSize.Max}
          show={isCopied}
          text={t`Copied`}
        >
          <CopyIcon />
        </Tooltip>
      </div>
    </div>
  )
}

const TokenLink = ({ token, logo }: { token?: PoolDetailToken; logo?: string }) => {
  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row items-center gap-2">
        <img className="h-6 w-6" src={logo || DefaultToken} />
        <span className="text-lg font-medium text-white"> {token?.symbol} </span>
      </div>
      <div className="flex flex-row gap-[10px]">
        <CopyLink address={token?.id} />

        <a href={`https://etherscan.io/address/${token?.id}`}>
          <EthersIcon />
        </a>
      </div>
    </div>
  )
}

export default PoolDetails
