const L2X_EXTENDED_LIST = {
  name: 'Uniswap Labs Default',
  timestamp: '2023-08-04T01:49:22.920Z',
  version: {
    major: 11,
    minor: 5,
    patch: 0,
  },
  tags: {},
  logoURI: 'ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir',
  keywords: ['uniswap', 'default'],
  tokens: [
    {
      name: 'Uniswap',
      address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      symbol: 'UNI',
      decimals: 18,
      chainId: 11155111,
      logoURI: 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
    },
    {
      name: 'Wrapped Ether',
      address: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
      symbol: 'WETH',
      decimals: 18,
      chainId: 11155111,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
    {
      name: 'Wrapped Ether',
      address: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      symbol: 'WETH',
      decimals: 18,
      chainId: 59140,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
    {
      chainId: 59140,
      address: '0xf4fA92d67D4BE549efd3b2251f022971Dd2Aa56F',
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
    },
    {
      chainId: 59140,
      address: '0xEcF4F13B8a0E2b9a82172d4456D2ade209830470',
      name: 'USDT',
      symbol: 'USDT',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
    {
      name: 'Wrapped Ether',
      address: '0xd2480162Aa7F02Ead7BF4C127465446150D58452',
      symbol: 'WETH',
      decimals: 18,
      chainId: 1261120,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
    {
      chainId: 1261120,
      address: '0x078904a7A7eE26Efd0708269C8Bc07D88F20E3D5',
      name: 'USDT',
      symbol: 'USDT',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
    {
      chainId: 1261120,
      address: '0xe245C9F377876222e42Bd3f1936b86F2C97D2c9d',
      name: 'USDC',
      symbol: 'USDC',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    },
    {
      chainId: 3776,
      address: '0xE9CC37904875B459Fa5D0FE37680d36F1ED55e38',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
    {
      chainId: 3776,
      address: '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    },
    {
      chainId: 3776,
      address: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
    {
      chainId: 3776,
      address: '0x5D8cfF95D7A57c0BF50B30b43c7CC0D52825D4a9',
      name: 'Wrapped liquid staked Ether 2.0',
      symbol: 'wstETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/32890/standard/WSTETH.png',
    },
    {
      chainId: 3776,
      address: '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    },
    {
      chainId: 3776,
      address: '0xdf41220C7e322bFEF933D85D01821ad277f90172',
      name: 'Astar Token',
      symbol: 'ASTR',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/22617/standard/astr.png',
    },
    {
      chainId: 3776,
      address: '0x7Cb5d4D178d93D59ea0592abF139459957898a59',
      name: 'DOT Token',
      symbol: 'DOT',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png',
    },
    {
      chainId: 3776,
      address: '0xa2036f0538221a77A3937F1379699f44945018d0',
      name: 'Matic Token',
      symbol: 'MATIC',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/4713/standard/polygon.png',
    },
    {
      chainId: 3776,
      address: '0x7746ef546d562b443AE4B4145541a3b1a3D75717',
      name: 'Bifrost Voucher ASTR',
      symbol: 'vASTR',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/34706/standard/vASTR.png',
    },
    {
      chainId: 3776,
      address: '0x80137510979822322193FC997d400D5A6C747bf7',
      name: 'StakeStone Ether',
      symbol: 'STONE',
      decimals: 18,
      logoURI: 'https://img.cryptorank.io/coins/stake_stone_eth1705501585759.png',
    },
    {
      chainId: 6038361,
      address: '0xAFfE538E9248F92E90ad26b6E5e9a391CB5b8b73',
      name: 'USDC',
      symbol: 'USDC',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    },
    {
      chainId: 6038361,
      address: '0x2fbc5Ba5927e9dd59C395C1d29235B2fd5f75071',
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
    {
      chainId: 6038361,
      address: '0xb7eb366ba269752776619ce6c79d25ac4c55a477',
      name: 'Wrapped BTC',
      symbol: 'WBTC',
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
    },
  ],
}

interface Token {
  name: string
  address: string
  symbol: string
  decimals: number
  chainId: number
  logoURI: string
}

export function getTokenInfo(symbol: string, chainId: number): Token | undefined {
  return L2X_EXTENDED_LIST.tokens.find(
    (token) => token.symbol.toLowerCase() === symbol.toLowerCase() && token.chainId === chainId
  )
}
