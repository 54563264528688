import styled from 'styled-components'
import { textFadeIn } from 'theme/styles'

const SWAP_COMPONENT_WIDTH = 412

export const TokenDetailsLayout = styled.div`
  display: flex;
  padding: 0 16px 52px;
  justify-content: center;
  width: 100%;
  gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 48px 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LeftPanel = styled.div`
  flex: 1;
  max-width: 780px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const RightPanel = styled.div`
  display: flex;
  padding-top: 57px;
  flex-direction: column;
  gap: 40px;
  width: ${SWAP_COMPONENT_WIDTH}px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: 100%;
    max-width: 780px;
  }
`

export const TokenInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 20px;
  gap: 20px;
  ${textFadeIn};
  animation-duration: ${({ theme }) => theme.transition.duration.medium};
`
