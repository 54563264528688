import { t, Trans } from '@lingui/macro'
import { PriceChart } from 'components/Charts2/PriceChart'
import { ChartType, PriceChartType } from 'components/Charts2/utils'
import { VolumeChart } from 'components/Charts2/VolumeChart'
import { CopyLink } from 'components/Pools/PoolDetails'
import { BackIcon, CopyURLIcon, EthersIcon } from 'components/Pools/PoolDetails/icons'
import Row from 'components/Row'
import { BreadcrumbNavLink } from 'components/Tokens/TokenDetails/BreadcrumbNavLink'
import { AdvancedPriceChartToggle } from 'components/Tokens/TokenDetails/ChartSection2/AdvancedPriceChartToggle'
import { ChartTypeDropdown } from 'components/Tokens/TokenDetails/ChartSection2/ChartTypeSelector'
import { ActionButtonStyle } from 'components/Tokens/TokenDetails/shared'
import { TokenDetailsPageSkeleton, TokenNameCell } from 'components/Tokens/TokenDetails/Skeleton'
import { StatPair, StatsWrapper, StatWrapper } from 'components/Tokens/TokenDetails/StatsSection'
import Tooltip, { MouseoverTooltip, TooltipSize } from 'components/Tooltip'
import { getTokenInfo } from 'constants/list-data'
import { nativeOnChain } from 'constants/tokens'
import { useTokenDatas, useTokenDetail } from 'graphql/data/pools/usePoolData'
import { getValidUrlChainName, supportedChainIdFromGQLChain, TimePeriod } from 'graphql/data/util'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useScreenSize } from 'hooks/useScreenSize'
import { UTCTimestamp } from 'lightweight-charts'
import NotFound from 'pages/NotFound'
import { Swap } from 'pages/Swap'
import { ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { EllipsisStyle, ThemedText } from 'theme'
import { Z_INDEX } from 'theme/zIndex'
import { NumberType, useFormatter } from 'utils/formatNumbers2'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { LeftPanel, RightPanel, TokenDetailsLayout, TokenInfoContainer } from './Skeleton'
import { TokenDetailsTransactionsTable } from './TransactionTable'

const CopyURL = () => {
  const [isCopied, setCopied] = useCopyClipboard()
  return (
    <div
      className="flex flex-row items-center cursor-pointer"
      onClick={() => {
        setCopied(window.location.href)
      }}
    >
      <Tooltip
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        placement="bottom"
        size={TooltipSize.Max}
        show={isCopied}
        text={t`Copied`}
      >
        <CopyURLIcon />
      </Tooltip>
    </div>
  )
}

const TDP_CHART_HEIGHT_PX = 356
const TDP_CHART_SELECTOR_OPTIONS = [ChartType.PRICE, ChartType.VOLUME, ChartType.TVL] as const

const TokenTitle = styled.div`
  display: flex;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
`

const TokenSymbol = styled(ThemedText.SubHeaderSmall)`
  font-size: 24px !important;
  line-height: inherit;
  margin-top: 0;
  margin-bottom: 0;

  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
    display: none;
  }
`

const TokenName = styled(ThemedText.HeadlineMedium)`
  ${EllipsisStyle}
  font-size: 24px !important;
  min-width: 40px;
`

const TokenStatsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Header = styled(ThemedText.MediumHeader)`
  font-size: 28px !important;
  padding-top: 40px;
`

const StatPrice = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.white};
`

const HeaderActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xs}px) {
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    align-items: unset;
    width: 100vw;
    padding: 8px;
    background: ${({ theme }) => theme.surface2};
    border-radius: 12px 12px 0 0;
    border: ${({ theme }) => `1px solid ${theme.surface3}`};
    box-shadow: ${({ theme }) => theme.deprecated_deepShadow};
    opacity: 1 !important;
    z-index: ${Z_INDEX.modal};
  }
`

const ActionButton = styled(Row)`
  ${ActionButtonStyle}

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xs}px) {
    color: unset;
    background-color: unset;
    width: unset;

    align-items: center;
    text-decoration: none;
    cursor: pointer;
    gap: 12px;
    padding: 10px 8px;
    border-radius: 8px;
    &:hover {
      background: ${({ theme }) => theme.surface3};
      opacity: 1;
    }
  }
`

const HR = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.surface3};
  width: 100%;
`

type NumericStat = number | undefined | null

function Stat({
  dataCy,
  value,
  title,
  description,
}: {
  dataCy: string
  value: NumericStat
  title: ReactNode
  description?: ReactNode
}) {
  const { formatNumber } = useFormatter()

  return (
    <StatWrapper data-cy={`${dataCy}`}>
      <MouseoverTooltip disabled={!description} text={description}>
        {title}
      </MouseoverTooltip>
      <StatPrice className="text-2xl-medium">
        {formatNumber({
          input: value,
          type: NumberType.FiatTokenStats,
        })}
      </StatPrice>
    </StatWrapper>
  )
}

export type TimePeriodType = '1H' | '1D' // | '1W' | '1M' | '1Y'

export const TimePeriodGroup = ({
  value,
  onChange,
}: {
  value: TimePeriodType
  onChange: (timePeriodType: TimePeriodType) => void
}) => {
  const [currentTimePeriod, setCurrentTimePeriod] = useState<TimePeriodType>(value)
  const datas: TimePeriodType[] = ['1H', '1D'] // , '1W', '1M', '1Y']
  return (
    <div className="flex flex-row gap-3">
      {datas.map((item) => {
        if (currentTimePeriod === item) {
          return (
            <div key={item} className="bg-secondary flex items-center justify-center shadow-button h-[36]px px-4">
              <span className="text-sm text-white">{item}</span>
            </div>
          )
        } else {
          return (
            <div
              onClick={() => {
                setCurrentTimePeriod(item)
                onChange(item)
              }}
              key={item}
              className="border-[1px] flex items-center justify-center bg-dark-base-200 border-dark-base-content-04 shadow-button h-[36]px px-4"
            >
              <span className="text-sm text-dark-base-content-06" style={{ lineHeight: '14px !important' }}>
                {item}
              </span>
            </div>
          )
        }
      })}
    </div>
  )
}

export default function TokenDetails() {
  const { tokenAddress, chainName } = useParams<{
    tokenAddress: string
    chainName?: string
  }>()

  const chain = getValidUrlChainName(chainName)
  const chainId = chain && supportedChainIdFromGQLChain(chain)

  const theme = useTheme()
  const screenSize = useScreenSize()
  const isMobileScreen = !screenSize['xs']
  const [priceChartType, setPriceChartType] = useState<PriceChartType>(PriceChartType.LINE)
  const [chartType, setChartType] = useState<ChartType>(ChartType.PRICE)
  const [currentTimePeriod, setCurrentTimePeriod] = useState<TimePeriodType>('1D')

  const { tokenDetail } = useTokenDetail(tokenAddress)
  const { tokenHourDatas, tokenDayDatas } = useTokenDatas(tokenAddress)

  if (!chain || !chainId || !tokenAddress) return <NotFound />
  if (!tokenDetail) return <TokenDetailsPageSkeleton />

  const tokenInfo = getTokenInfo(tokenDetail.symbol, chainId)
  const nativeToken = nativeOnChain(chainId)
  const explorerUrl = getExplorerLink(
    chainId,
    tokenAddress,
    nativeToken.wrapped.address === tokenAddress ? ExplorerDataType.NATIVE : ExplorerDataType.TOKEN
  )

  return (
    <TokenDetailsLayout>
      <LeftPanel>
        <Breadcrumb />
        <div className="bg-dark-base-200 p-3">
          <TokenInfoContainer data-testid="token-info-container">
            <TokenNameCell>
              <img className="w-8 h-8" src={tokenInfo?.logoURI} />
              <TokenTitle>
                <TokenName>{tokenDetail.name ?? <Trans>Name not found</Trans>}</TokenName>
                <TokenSymbol>{tokenDetail.symbol}</TokenSymbol>
                <CopyLink address={tokenAddress} />
              </TokenTitle>
            </TokenNameCell>
            <HeaderActionsContainer>
              {explorerUrl && (
                <a href={`https://etherscan.io/address/${tokenAddress}`}>
                  <EthersIcon />
                </a>
              )}
              <CopyURL />
            </HeaderActionsContainer>
          </TokenInfoContainer>
          {chartType === ChartType.PRICE ? (
            tokenDayDatas.length > 0 && (
              <PriceChart
                data={(currentTimePeriod === '1D' ? tokenDayDatas : tokenHourDatas).map((item: any) => ({
                  time: (currentTimePeriod === '1D' ? item.date : item.periodStartUnix) as UTCTimestamp,
                  value: item.priceUSD,
                  close: item.close,
                  feesUSD: item.feesUSD,
                  high: item.high,
                  id: item.id,
                  low: item.low,
                  open: item.open,
                  priceUSD: item.priceUSD,
                  totalValueLocked: item.totalValueLocked,
                  totalValueLockedUSD: item.totalValueLockedUSD,
                  untrackedVolumeUSD: item.untrackedVolumeUSD,
                  volume: item.volume,
                  volumeUSD: item.volumeUSD,
                }))}
                tokenDetail
                height={TDP_CHART_HEIGHT_PX}
                type={priceChartType}
                stale={false}
              />
            )
          ) : (
            <VolumeChart
              timePeriod={currentTimePeriod === '1D' ? TimePeriod.DAY : TimePeriod.HOUR}
              height={356}
              data={(currentTimePeriod === '1D' ? tokenDayDatas : tokenHourDatas).map((item: any) => {
                return {
                  time: (currentTimePeriod === '1D' ? item.date : item.periodStartUnix) as UTCTimestamp,
                  value: chartType === ChartType.VOLUME ? item.volumeUSD : item.totalValueLockedUSD,
                }
              })}
              stale={false}
            />
          )}
          <div className="flex flex-row w-full justify-between">
            <TimePeriodGroup value={currentTimePeriod} onChange={setCurrentTimePeriod} />

            <div className="flex flex-row gap-4">
              <AdvancedPriceChartToggle
                currentChartType={priceChartType}
                onChartTypeChange={setPriceChartType}
                disableCandlestickUI={false}
              />
              <ChartTypeDropdown
                options={TDP_CHART_SELECTOR_OPTIONS}
                currentChartType={chartType}
                onSelectOption={(c) => {
                  setChartType(c)
                  if (c === ChartType.PRICE) setPriceChartType(PriceChartType.LINE)
                }}
              />
            </div>
          </div>
          <StatsWrapper data-testid="token-details-stats">
            <div className="text-2xl-medium text-white mt-3">
              <Trans>Stats</Trans>
            </div>
            <TokenStatsSection>
              <StatPair>
                <Stat
                  dataCy="tvl"
                  value={tokenDetail.totalValueLockedUSD}
                  description={
                    <Trans>
                      Total value locked (TVL) is the aggregate amount of the asset available across all Uniswap v3
                      liquidity pools.
                    </Trans>
                  }
                  title={<Trans>TVL</Trans>}
                />
                {/* <Stat
                  dataCy="market-cap"
                  value={0}
                  description={
                    <Trans>
                      Market capitalization is the total market value of an asset&apos;s circulating supply.
                    </Trans>
                  }
                  title={<Trans>Market cap</Trans>}
                /> */}
                <Stat
                  dataCy="fdv"
                  value={Number(tokenHourDatas.at(-1)?.priceUSD || '0') * tokenDetail.totalSupply}
                  title={<Trans>FDV</Trans>}
                  description={
                    <Trans>
                      Fully diluted valuation (FDV) calculates the total market value assuming all tokens are in
                      circulation.
                    </Trans>
                  }
                />
              </StatPair>
              <StatPair>
                <Stat
                  dataCy="volume-24h"
                  value={tokenDetail.volumeUSD}
                  description={
                    <Trans>
                      1 day volume is the amount of the asset that has been traded on Uniswap v3 during the past 24
                      hours.
                    </Trans>
                  }
                  title={<Trans>1 day volume</Trans>}
                />
              </StatPair>
            </TokenStatsSection>
          </StatsWrapper>

          <hr className="border-t-1" style={{ borderColor: '#A6ADBA', opacity: 0.1 }} />
        </div>

        <div className="font-medium text-2xl text-dark-base-content">Transactions Pools</div>
        <TokenDetailsTransactionsTable tokenAddress={tokenAddress} chainId={chainId} symbol={tokenInfo?.symbol} />
      </LeftPanel>
      <RightPanel>
        <Swap chainId={chainId} hideBg />
      </RightPanel>
    </TokenDetailsLayout>
  )
}

const BreadcrumbNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

function Breadcrumb() {
  return (
    <BreadcrumbNavContainer aria-label="breadcrumb-nav">
      <BreadcrumbNavLink to="/explore/tokens">
        <BackIcon />
        <Trans>Tokens</Trans>
      </BreadcrumbNavLink>
    </BreadcrumbNavContainer>
  )
}
