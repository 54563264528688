import { Trans } from '@lingui/macro'
import DefaultToken from 'assets/svg/icons/default-token.svg'
import Column from 'components/Column'
import Row from 'components/Row'
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta'
import { getTokenInfo } from 'constants/list-data'
import { BigNumber } from 'ethers'
import { PoolDetail } from 'graphql/data/pools/usePoolData'
import { ReactNode } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { BREAKPOINTS, ThemedText } from 'theme'
import { NumberType, useFormatter } from 'utils/formatNumbers2'

import ProgressBar from './Progress'

const HeaderText = styled(Text)`
  font-weight: 485;
  font-size: 24px;
  line-height: 36px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
`

const StatsWrapper = styled(Column)`
  gap: 24px;
  padding: 20px;
  background: ${({ theme }) => theme.base200};
  width: 100%;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    background: transparent;
    flex-wrap: wrap;
    padding: 20px 0px;
    justify-content: space-between;
    margin-top: 0px;
  }
`

const StatItemColumn = styled(Column)`
  gap: 8px;
  flex: 1;
  min-width: 180px;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    min-width: 150px;
  }
`

export function PoolDetailsStats({ poolData, chainId }: { poolData?: PoolDetail; chainId: number }) {
  const token0 = getTokenInfo(poolData?.token0?.symbol || '', chainId)
  const token1 = getTokenInfo(poolData?.token1?.symbol || '', chainId)

  const price0 = BigNumber.from(Number(poolData?.token0Price?.toFixed?.(4) || '0'))
  const price1 = BigNumber.from(Number(poolData?.token1Price?.toFixed?.(4) || '0'))
  const token0TotalSupply = BigNumber.from(poolData?.token0.totalSupply || '0')
  const token1TotalSupply = BigNumber.from(poolData?.token1.totalSupply || '0')

  const value = price1.mul(token1TotalSupply).eq(0)
    ? 0.5
    : Number(price0.mul(token0TotalSupply).div(price1.mul(token1TotalSupply)).toString())

  return (
    <StatsWrapper>
      <HeaderText>
        <Trans>Stats</Trans>
      </HeaderText>

      <div className="flex flex-col gap-2">
        <span>Pool balances</span>

        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row items-center gap-[6px]">
            <span className="text-medium text-sm text-white">{price0.mul(token0TotalSupply).toString()}</span>
            <img src={token0?.logoURI || DefaultToken} className="w-5 h-5" />
            <span className="text-medium text-sm text-white">{token0?.symbol}</span>
          </div>

          <div className="flex flex-row items-center gap-[6px]">
            <span className="text-medium text-sm text-white">{price1.mul(token1TotalSupply).toString()}</span>
            <img src={token1?.logoURI || DefaultToken} className="w-5 h-5" />
            <span className="text-medium text-sm text-white">{token1?.symbol}</span>
          </div>
        </div>
        <ProgressBar value={value} />
      </div>

      {poolData?.totalValueLockedUSD && (
        <StatItem title={<Trans>TVL</Trans>} value={Number(poolData.totalValueLockedUSD)} />
      )}
      {poolData?.volumeUSD !== undefined && (
        <StatItem title={<Trans>24H volume</Trans>} value={Number(poolData.volumeUSD)} />
      )}
      {poolData?.volumeUSD !== undefined && poolData?.feeTier !== undefined && (
        <StatItem title={<Trans>24H fees</Trans>} value={Number(poolData.volumeUSD) * (poolData.feeTier / 1000000)} />
      )}
    </StatsWrapper>
  )
}

const StatsTextContainer = styled(Row)`
  gap: 4px;
  width: 100%;
  align-items: flex-end;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
  }
`

const StatItemText = styled(Text)`
  color: ${({ theme }) => theme.neutral1};
  font-size: 36px;
  font-weight: 485;
  line-height: 44px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

function StatItem({ title, value, delta }: { title: ReactNode; value: number; delta?: number }) {
  const { formatNumber, formatDelta } = useFormatter()

  return (
    <StatItemColumn>
      <ThemedText.BodySecondary>{title}</ThemedText.BodySecondary>
      <StatsTextContainer>
        <StatItemText>
          {formatNumber({
            input: value,
            type: NumberType.FiatTokenStats,
          })}
        </StatItemText>
        {!!delta && (
          <Row width="max-content" padding="4px 0px">
            <DeltaArrow delta={delta} />
            <ThemedText.BodySecondary>{formatDelta(delta)}</ThemedText.BodySecondary>
          </Row>
        )}
      </StatsTextContainer>
    </StatItemColumn>
  )
}
